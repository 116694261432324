<template>
  <base-layout-two
    page-title="Edit Task"
    :page-default-back-link="`/tasks/${this.$route.params.id}`"
  >
    <div class="padding-8">
      <v-form @submit="onSubmit">
        <base-card title="">
          <ion-grid class="ion-no-padding grid-padding">
            <ion-row>
              <!-- Title -->
              <ion-col size="12">
                <base-input label-text="Title *">
                  <v-field name="title" v-slot="{ field }" v-model="task.title">
                    <ion-input
                      name="title"
                      v-bind="field"
                      placeholder=""
                      type="text"
                      :rules="required"
                      autocapitalize
                      autocorrect
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="title" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Description -->
              <ion-col size="12">
                <base-input label-text="Description">
                  <v-field
                    name="description"
                    v-slot="{ field }"
                    v-model="task.description"
                  >
                    <ion-textarea
                      :auto-grow="true"
                      rows="1"
                      v-bind="field"
                      placeholder=""
                      autocapitalize
                      autocorrect
                    >
                    </ion-textarea>
                  </v-field>
                </base-input>
                <v-error-message name="description" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Due Date -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Due Date">
                  <v-field
                    name="due_date"
                    v-slot="{ field }"
                    v-model="task.due_date"
                  >
                    <ion-input
                      name="due_date"
                      v-bind="field"
                      placeholder=""
                      type="date"
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="due_date" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Assigned To -->
              <ion-col size="12" size-lg="6" v-if="false">
                <base-input label-text="Assigned to">
                  <v-field
                    name="assigned_to"
                    v-slot="{ field }"
                    v-model="task.assigned_to"
                  >
                    <select v-bind="field" class="regular-select">
                      <option :value="1">Sarah Mallin</option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="assigned_to" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Assigned Role -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Assigned to">
                  <v-field
                    name="assigned_role"
                    v-slot="{ field }"
                    v-model="task.assigned_role"
                  >
                    <select v-bind="field" class="regular-select">
                      <option value="tasks_manager">Managers</option>
                      <option value="tasks_care_associate">Care Associates</option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="assigned_role" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-button type="submit" expand="block" class="margin-y-16">
            Save
          </ion-button>

          <ion-button
            type="submit"
            expand="block"
            class="margin-y-16"
            color="danger"
          >
            Delete
          </ion-button>
        </base-card>
      </v-form>
    </div>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
  IonButton,
} from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonTextarea,
    IonButton,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      task: {},
      required: yup.string().required(),
    };
  },

  ionViewWillEnter() {
    this.fetchTask();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async fetchTask() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/tasks/${this.$route.params.id}`)
        .then((response) => {
          this.task = response.data.task;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async onSubmit() {
      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/tasks/${this.$route.params.id}`,
        data: this.task,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.task = response.data.task;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Task Created", color: "secondary" });

          this.$router.back();
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>